.jobs__list {
    height: 75vh;
    overflow: auto;
}

.jobs__search {
    border:none;
    border-bottom:1px solid grey;
    transition:.3s all ease-in-out;
}

.jobs__search .jobs__searchInput:focus  {
    border-bottom:1px solid blue;
}

.text-gray-500 {
    color: rgb(123 127 147);
}
.text-lg {
    font-size: 1.125rem;
}

.jobs__searchInput {
    border:none;
    outline:none
}

.jobs__searchInput:focus {
    border:none !important;
}
.search_loaction {
    border:none;
    padding: 2px;
    outline: none;
}

.search_loaction::placeholder, .search_loaction {
    color: rgb(123 127 147);
}