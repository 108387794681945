.recprofile__avatar {
	border-radius: 100px;
	width: 150px;
	height: 150px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #1890ff;
}

.recprofile__avatar:hover {
	border: 1px solid orange;
}

.recprofile__avatar img {
	width: 140px;
	height: 140px;
	border-radius: 100px;
}

.user-card {
	margin: 20px;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}


.user-card-header {
	background-color: #1890ff;
	color: #fff;
	padding: 20px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
}

.user-card-header h3 {
	margin: 0;
	font-size: 24px;
}

.user-card-header p {
	margin: 0;
	font-size: 16px;
}

.user-card-body {
	padding: 20px;
    text-align: left;
}

.recprofile__edit {
    position: absolute;
    top: -10px;
    right: -10px;
    padding: 5px;
    background-color: #1890ff;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    color: #fff;
    border-radius: 100px;
}

.recprofile__edit:hover {
    background-color: orange !important;
    cursor: pointer;
}