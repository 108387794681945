@media screen and (max-width:700px) {
    .jobdetail__container {
        flex-direction: column-reverse !important;
    }
    .jobdetail__applynowcontainer {
        height:auto !important;
        border-bottom: 1px solid #dee2e6 !important;
        width:100%;
    }
    .jobdetail__locationdetails {
        border:none !important;
        padding: 10px !important;
    }
    .jobdetails__skillandlocation {
        gap: 0px !important;
    }
}