.login__selectlogintype {
  padding: 10px 20px;
  border: 1px solid grey;
  cursor: pointer;
  border-radius: 10px;
  transition: 0.2s all ease-in-out;
}

.login__selectlogintype:hover,
.active {
  background-color: grey;
  color: black;
}

.login__container {
  height: 100vh;
}

@media screen and (max-width: 400px) {
  .login__image img {
    object-fit: contain;
    width: 100%;
  }
  .login__arhalogo {
    width: 100px;
  }
}

.login__ahralogo {
  width: 100px;
}
