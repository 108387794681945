.jobcard__container {
    height: 200px;
    cursor: pointer;
    width:335px;
    border-radius: 10px !important;
    border: 1px solid;
    border-color: rgb(241 241 244/1);
    transition: .2s all ease;

}

.jobcard__container:hover{
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.13);
}

.jobcard__username {
    --tw-text-opacity: 1;
    color: rgb(123 127 147/var(--tw-text-opacity));
    font-size: .75rem;
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(123 127 147/var(--tw-text-opacity));
}

.jobcard__title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    font-weight: 600;
    text-align: left;
}

.jobcard__des {
    text-align: left;
    font-size: 12px;
}

.jobcard__badge {
    background-color: rgb(233 251 233);
    text-align: center;
}