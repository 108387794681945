.signupform__container {
	padding: 30px 50px !important;
}

.signup__container {
	height: 90vh;
}

.signupform__container {
	height: 90%;
	overflow: auto;
}

@media screen and (max-width: 400px) {
	img {
		width: 90%;
	}
	.signup__container {
		padding: 10px !important;
	}
	.signupform__container {
		width: 100%;
		height: 100%;
		overflow: none;
	}
}
